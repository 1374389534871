body {
    margin: 0;
    padding: 0;
    font-family: 'Raleway-Regular', sans-serif;
    font-weight: 100;
}

.container {
    max-width: 400px;
    margin: auto;
    background: #000000;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form {
    display: flex;
    flex-direction: column;
}

.input {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.fieldError {
    color: red;
    font-size: 0.9em;
    display: none;
}

.button {
    background-color: #c7c7c7;
    color: #111349;
    cursor: pointer;
    padding: 10px;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #111349;
    color: #c7c7c7;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.modal {
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.modalContent {
    position: relative;
    background: white;
    padding: 20px;
    max-width: 500px;
    max-height: 70vh;
    overflow-y: auto;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

/* Мобильные стили */
@media only screen and (max-width: 768px) {
    .container {
        width: 90%;
    }
}

@media (max-width: 450px) {
    .button {
        font-size: 10px;
        padding: 6px;
    }
}