.chatPage::before {
  content: "";
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.35);
  /* затемнение видео */
  z-index: 0;
}

.chatPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  font-family: 'Segoe UI', sans-serif;
  padding: 24px;
  box-sizing: border-box;
}

.chatContainer {
  display: flex;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  max-height: 820px;
  background-color: rgba(255, 255, 255, 0.9);
  /* Прозрачный белый */
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  z-index: 1;
  backdrop-filter: blur(8px);
  /* если хочешь soft-glass эффект */
}