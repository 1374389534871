.notification {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: #111349;
    color: white;
    padding: 14px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    font-size: 16px;
    z-index: 9999;
    overflow: hidden;
    min-width: 220px;
    animation: fadeIn 0.3s ease-out;
}

.progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background: #fff;
    width: 100%;
    animation-name: progressOut;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes progressOut {
    from {
        width: 100%;
    }

    to {
        width: 0%;
    }
}

@keyframes fadeIn {
    from {
        transform: translateY(10px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}