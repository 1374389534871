body {
    margin: 0;
    padding: 0;
    font-family: 'Raleway-Regular', sans-serif;
    font-weight: 100;
    height: auto; /* Даем body возможность скроллиться */
    overflow-x: hidden; /* Запрещаем горизонтальный скролл, но оставляем вертикальный */
}

/* Контейнер для видео */
.videoContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
}

/* Видео iframe */
.backgroundVideo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120vw;
    height: 120vh;
    min-width: 100vw;
    min-height: 100vh;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

@media only screen and (max-width: 768px) {
    .videoContainer {
        height: 127vh; /* Даем немного больше высоты, если нужно */
    }
}