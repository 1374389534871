/* Стили для футера */
.footer {
    color: white;
    padding: 180px 0;
    margin-top: 155px;
    min-height: 50vh;
    width: 100%;
    background: linear-gradient(45deg, #111349, #060838, #000000);
    opacity: 0.9;
}

.footerContainer {
    padding-top: 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 auto;
    margin-left: 10%;
}

.footerSection {
    flex: 1;
    padding: 0 10px;
}

.footerSection a {
    text-decoration: none;
    display: block;
    margin: 5px 0;
}

.footerBottom {
    display: flex;
    margin-top: 20px;
    font-size: 14px;
    justify-content: center;
}

.socialLogo {
    display: flex;
    flex-wrap: wrap;
    margin-right: 100px;
    justify-content: space-around;
    margin-top: 40px;
}

.socialLogs {
    width: 40px;
    height: auto;
    transition: all 1s ease;
}

.socialLogs:hover {
    width: 50px;
}