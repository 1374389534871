.message {
    padding: 10px 14px;
    border-radius: 18px;
    margin: 6px 0;
    max-width: 75%;
    font-size: 14px;
    line-height: 1.5;
    word-break: break-word;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    position: relative;
    display: inline-block;
}

.user {
    background-color: #e3f2fd;
    align-self: flex-start;
    border-bottom-left-radius: 4px;
}

.seller {
    background-color: #d0f0c0;
    align-self: flex-end;
    border-bottom-right-radius: 4px;
}

.timeStamp {
    font-size: 12px;
    color: #888;
    margin-top: 5px;
    text-align: right;
}