.chatButton {
    padding: 8px 16px;
    background-color: #111349;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    width: 100%; 
}

.chatButton:hover {
    background-color: #0d0f3b;
}