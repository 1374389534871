/* 🌐 Основной контейнер */
.container-m {
  display: flex;
  justify-content: center;
  z-index: -10;
}

.container-c {
  display: flex;
  justify-content: center;
  position: relative;
  background: linear-gradient(90deg, #111349, #060838, #000000);
  padding: 20px;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  opacity: 0.9;
  margin-top: 100px;
  color: #ffffff;
  z-index: 1;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

/* 📌 Общие элементы */
h1, h2 {
  text-align: center;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 30px;
}

.cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 50px;
}

/* 🎟️ Дисконтная карта */
.discount-card {
  width: 300px;
  height: 150px;
  margin: 0 auto 20px;
  background: linear-gradient(135deg, #e0e6ae, #e7b434);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  position: relative;
}

.strip {
  width: 100%;
  height: 30px;
  background-color: #000000;
  margin-bottom: 20px;
}

.chip {
  width: 40px;
  height: auto;
  margin-right: 180px;
  margin-bottom: 20px;
}

.discount-card span {
  font-family: 'Courier New', Courier, monospace;
}

/* 🔍 Поисковые окна */
.search-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.search-container input, .search-container button {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}

.search-container input {
  flex: 1;
  min-width: 450px;
}

.search-container button {
  background-color: #006d92;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-container button:hover {
  background-color: #004b62;
}

/* 🔘 Кнопка фильтра */
.status-button {
  margin-top: 10px;
  padding: 8px 15px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ccc;
  color: black;
  transition: background-color 0.3s ease;
}

.status-button.active {
  background-color: #28a745;
  color: white;
}

.status-button:hover {
  opacity: 0.8;
}

/* 🏢 Секция партнеров */
.partners-section {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
}

/* 🎭 Карточка партнёра */
.partner-card {
  width: 300px;
  height: 350px;
  perspective: 1000px;
  position: relative;
  background: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.partner-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* 🌀 3D-эффект переворота */
.card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.partner-card:hover .card-inner {
  transform: rotateY(180deg);
}

/* Передняя и задняя стороны */
.card-front, .card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

/* 🎭 Передняя сторона */
.card-front {
  background: white;
}

/* 🎭 Задняя сторона */
.card-back {
  background: #ffffff;
  color: white;
  transform: rotateY(180deg);
}

/* 📌 Контент карточки */
.partner-logo {
  width: 100%;
  height: 80%;
  object-fit: cover;
}

.partner-card-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex-grow: 1;
}

.partner-name {
  font-weight: bold;
  font-size: 24px;
  color: #333;
}

.partner-discount {
  font-size: 20px;
  color: #555;
}

.partner-description {
  font-size: 14px;
  margin: 10px 0;
  color: black;
}

/* 🎯 Кнопка амбассадора (по умолчанию) */
.partner-status-button {
  margin-top: 10px;
  padding: 8px 15px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* 🔵 Синий цвет (если тариф куплен) */
.partner-status-button.active {
  background-color: #007bff;
  color: white;
}

.partner-status-button.active:hover {
  background-color: #0056b3;
}

/* 🟢 Зелёный цвет (если юзер уже амбассадор) */
.partner-status-button.ambassador {
  background-color: #2ecc71;
  color: white;
  font-weight: bold;
  border: 1px solid #27ae60;
}

.partner-status-button.ambassador:hover {
  background-color: #27ae60;
}

/* ⚪ Белый цвет (если тариф неактивен) */
.partner-status-button.disabled {
  background-color: #ccc;
  color: black;
  cursor: not-allowed;
  opacity: 0.6;
}

/* 🔗 Блок ссылок */
.partner-links {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

/* Стили для гиперссылок */
.partner-link {
  color: #000000;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  transition: color 0.3s ease;
}

.partner-link:hover {
  color: #01356d;
  text-decoration: underline;
}

.partner-instagram {
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
}

.instagram-logo {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

/* 📱 Медиа-запросы */
@media only screen and (max-width: 768px) {
  .chip {
    width: 40px;
    height: auto;
    margin-left: 20px;
  }

  .partner-status-button {
    padding: 6px 12px;
    font-size: 12px;
  }

  /* Уменьшаем карточку на планшетах */
  .partner-card {
    width: 230px;
    height: 280px;
  }

  /* Уменьшаем размер контента */
  .partner-card-content {
    padding: 5px;
  }

  .partner-logo {
    height: 50%;
  }

  .partner-name, .partner-discount {
    font-size: 13px;
  }

  .partner-description {
    font-size: 11px;
    margin: 4px 0;
    color: black;
  }

  .partner-links {
    gap: 5px;
  }

  .partner-link {
    font-size: 12px; /* Уменьшаем размер текста */
  }

  .partner-link img {
    width: 16px; /* Делаем логотип меньше */
    height: 16px;
  }

  .partner-instagram {
    font-size: 12px; /* Уменьшаем текст */
  }

  .instagram-logo {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 450px) {
  .search-container input, .search-container button {
    font-size: 12px;
    padding: 10px;
    min-width: 50%;
  }

  /* Делаем карточки меньше на мобильных */
  .partner-card {
    width: 120px;
    height: 240px;
  }

  /* Делаем контент ещё компактнее */
  .partner-card-content {
    padding: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
  }

  .partner-logo {
    height: 45%;
  }

  .partner-name, .partner-discount {
    font-size: 12px;
  }

  .partner-status-button {
    padding: 6px 6px;
    font-size: 10px;
    margin-top: 6px;
    background-color: #35365d;
    color: white;
  }

  .partner-links {
    gap: 5px;
  }

  .partner-link {
    font-size: 10px; /* Уменьшаем размер текста */
  }

  .partner-link img {
    width: 16px; /* Делаем логотип меньше */
    height: 16px;
  }

  .partner-instagram {
    font-size: 11px;
  }

  .instagram-logo {
    width: 14px;
    height: 14px;
  }
}
