.containerM {
    display: flex;
    justify-content: center;
    z-index: -10;
}

.containerC {
    display: flex;
    position: relative;
    background: #111349;
    padding: 20px;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 87%;
    opacity: 0.9;
    margin-top: 100px;
    color: #ffffff;
    z-index: 1;
}

.container {
    flex: 1;
    padding: 20px;
}

.stats {
    margin-bottom: 20px;
}

.stats p {
    font-size: 18px;
    line-height: 1.5;
}

.dropdown {
    margin-bottom: 20px;
}

.selectActivation {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #9e3838;
    border-radius: 4px;
    width: 100%;
    margin-top: 10px;
    max-width: -webkit-fill-available;
}

@media (max-width: 768px) {
    .stats p {
        font-size: 16px;
    }

    .addItem {
        font-size: 14px;
        padding: 8px 12px;
    }
}