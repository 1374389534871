/* Общий контейнер */
.mainContainer {
    display: flex;
    justify-content: center;
    z-index: -10;
}

.contentContainer {
    width: 1000px;
    display: block;
    position: relative;
    background: #111349;
    padding: 20px;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 87%;
    opacity: 0.9;
    margin-top: 100px;
    color: #ffffff;
    z-index: 1;
}

/* Стили дерева */
.treeContainer {
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid #ccc;
    padding: 10px;
}

.tree {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;
}

.tree ul {
    display: grid;
    list-style: none;
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid #ccc;
}

.treeItem {
    margin: 5px 0;
    position: relative;
    cursor: pointer;
}

.treeItem span {
    padding: 5px 8px;
    display: inline-block;
    background-color: #111349;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 10px;
    white-space: nowrap;
}

.treeItem span:hover {
    background-color: #ddd;
    color: #111349;
}

.treeItem::before {
    content: "";
    position: absolute;
    top: 0;
    left: -10px;
    width: 10px;
    height: 1px;
    background: #ccc;
}

.treeItem::after {
    content: "";
    position: absolute;
    top: 0;
    left: -10px;
    width: 1px;
    height: 100%;
    background: #ccc;
}

.treeItem:last-child::after {
    height: 10px;
}

.hidden {
    display: none;
}

/* Медиа-правила */
@media only screen and (max-width: 768px) {
    .contentContainer {
        flex-direction: column;
        gap: 15px;
    }

    .treeContainer {
        padding: 10px;
    }
}

@media (max-width: 450px) {
    .treeItem span {
        font-size: 12px;
        padding: 3px;
    }
}