.orderCard {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 16px;
    background: #fdfdfd;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.03);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.statusBadge {
    background-color: #ffe082;
    color: #795548;
    font-size: 13px;
    padding: 4px 10px;
    border-radius: 20px;
    font-weight: 600;
}

.invoiceInfo {
    background-color: #fffde7;
    padding: 10px 14px;
    border-left: 4px solid #ffb300;
    border-radius: 8px;
    font-size: 14px;
    color: #5c3c00;
}

.actionButton {
    padding: 10px 16px;
    background-color: #388e3c;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.actionButton:hover {
    background-color: #2e7d32;
}