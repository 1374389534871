.block {
    background-color: #f4f6f8;
    border-left: 4px solid #111349;
    padding: 12px 16px;
    border-radius: 12px;
    font-size: 14px;
    color: #333;
    line-height: 1.6;
    margin: 8px 0;
    margin-bottom: 10px;
    max-width: 75%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    word-break: break-word;
    display: flex;
    flex-direction: column;
}

.timeStamp {
    font-size: 12px;
    color: #666;
    align-self: flex-end;
    margin-top: 5px;
}

.fromUser {
    align-self: flex-start;
    border-left-color: #42a5f5;
    background-color: #e3f2fd;
    border-bottom-left-radius: 4px;
}

.fromSeller {
    align-self: flex-end;
    border-left-color: #66bb6a;
    background-color: #e8f5e9;
    border-bottom-right-radius: 4px;
}

.tokenLine {
    margin-top: 10px;
    font-size: 13px;
    color: #555;
    display: flex;
    align-items: center;
    gap: 8px;
}

.tokenLine code {
    background: #e3f2fd;
    padding: 2px 6px;
    border-radius: 4px;
}

.filePreview {
    margin-top: 12px;
}

.previewGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 6px;
}

.previewItem {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
}

.previewItem img {
    max-width: 140px;
    max-height: 100px;
    border-radius: 6px;
    border: 1px solid #ccc;
    object-fit: cover;
}

.previewActions {
    display: flex;
    gap: 8px;
    font-size: 13px;
}

.previewActions a {
    text-decoration: none;
    color: #1976d2;
    transition: color 0.2s ease;
}

.previewActions a:hover {
    color: #0d47a1;
}

.returnNote {
    background: #fff8e1;
    border-left: 4px solid #ffb300;
    border-radius: 6px;
    padding: 10px 14px;
    font-size: 13px;
    color: #5c3c00;
    margin-top: 8px;
}

.footerNote {
    margin-top: 12px;
    font-size: 13px;
    color: #555;
}