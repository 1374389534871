.productCard {
    width: 200px;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    background: #f9f9f9;
    text-align: center;
    opacity: 0.9;
    cursor: pointer;
}

.one {
    width: 200px;
    height: 250px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.one video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
}

.one:hover video {
    opacity: 1;
    pointer-events: auto;
}

.two a {
    font-size: 14px;
    text-decoration: none;
    color: #333;
    display: block;
    text-align: left;
    margin-top: 10px;
}

.three {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
}

.three_a {
    font-size: 24px;
}

.three_b {
    color: black;
}

.six {
    font-size: 12px;
    color: #777;
    padding: 5px;
    text-align: center;
}

.four {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    color: white;
    margin-top: 10px;
}

.available {
    background-color: green;
}

.soldOut {
    background-color: red;
}

.five {
    font-size: 12px;
    text-align: center;
    color: #555;
    margin-top: 10px;
}

.button {
    margin-top: 5px;
    background-color: #c7c7c7;
    color: #111349;
    cursor: pointer;
    padding: 5px 25px;
    border-radius: 20px;
}

.footer_card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 40px;
    padding-bottom: 40px;
}

.product_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.watchCount,
.publishedAt {
    font-size: 14px;
    color: #666;
}